import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "../../../../utility/axios"

let axiosController = new AbortController()

export const fetchAgents = createAsyncThunk(
    "agentChat/fetchAgents",
    async () => {
        const response = await axios.get('/api/AgentMessage/GetUsers')
        if (response.data['succeeded']) return response.data.data
    }
)

export const loadMoreAgentMessages = createAsyncThunk(
    'agentChat/loadMoreMessages',
    async ({ agentId, groupId, page }) => {
        const response = await axios.get('/api/AgentMessage', {
            params: {userId: agentId, groupId, pageNumber: page},
            signal: axiosController.signal
        })
        if (response.data['succeeded']) return {id: agentId || groupId, ...response.data.data}
    })

export const selectChatForAgent = createAsyncThunk('agentChat/selectChat', async (
    {agentId, groupId, isGroup, agent}
) => {
    // const chatHistory = getState().agentChat.history
    // const history = chatHistory.find(_chat => _chat.id === agentId || _chat.groupId === groupId)

    // console.log("history", history)
    // Return from history
    // if (history) {
        // console.log('can read', canRead)
        // if (canRead) axios.post('api/contact/markAsRead', {
        //     contactId: agentId
        // }).then().catch(error => console.log(error))
        // return { id: agentId || groupId, isGroup, groupId, agent, history }
    //}

    // Fetch and return
    const response = await axios.get('/api/AgentMessage', {
        params: { userId: agentId, groupId },
        signal: axiosController.signal
    })

    // console.log("response->", response.data)

    if (response.data['succeeded']) {
        // if (canRead) axios.post('api/contact/markAsRead', {
        //     contactId: agentId
        // }).then().catch(error => console.log(error))

        // const _response = await axios.get('/api/message/getPinnedMessages', {
        //     params: {contactId: agentId}
        // })

        // console.log("response->", response)
        return { id: agentId || groupId, groupId, isGroup, agent, ...response.data.data }
    }
})

// const chatListSort = (a, b) => new Date(b.lastMessageDate) - new Date(a.lastMessageDate)
const messagesSort = (a, b) => new Date(a['createdDate']) - new Date(b['createdDate'])

export const agentChatSlice = createSlice({
    name: "agentChat",
    initialState: {
        isLoading: false,
        agentList: {
            items: [],
            paginator: {
                current: 1,
                hasNext: false
            }
        },
        activeChat: {
            isReady: false,
            id: null,
            isGroup: false,
            agent: null,
            pinnedMessages: [],
            messages: [],
            paginator: {
                current: 1,
                hasNext: false
            }
        },
        history: [],
        receiver: {},
        unreadMessageCount: 0,
        showChatTab: false
    },
    reducers: {
        deleteMessage: {
            reducer(state, action) {
                if (state.activeChat.id === action.payload.contactId) {
                    state.activeChat.messages = state.activeChat.messages.filter(
                        _msg => _msg.id !== action.payload.messageId
                    )
                } else {
                    const chat = state.history.find(_item => _item.id === action.payload.contactId)
                    if (chat) chat.messages = chat.messages.filter(
                        _msg => _msg.id !== action.payload.messageId
                    )
                }
            },
            prepare(contactId, messageId) {
                return {
                    payload: { contactId, messageId }
                }
            }
        },
        markAgentMessageAsRead: {
            reducer(state, action) {
                state.agentList.items = state.agentList.items?.map(user => {
                    return user.userId === action.payload.userId ? {...user, unreadMessageCount: 0 } : user
                })
            },
            prepare(userId) {
                return {
                    payload: { userId }
                }
            }
        },
        newMessage: {
            reducer(state, action) {
                // if (state.activeChat.id === action.payload.contactId)
                // console.log("newMessage", action.payload)
                state.activeChat.messages.push(action.payload)
                // else {
                //   const chat = state.history.find(_item => _item.id === action.payload.contactId)
                //   if (chat) chat.messages.push(action.payload)
                // }
            },
            prepare(data) {
                return {
                    payload: data
                }
            }
        },
        updateChatList: {
            reducer(state, action) {
                const data = action.payload.data
                // let found = false

                /*{
                    "userId": "a04304f5-bc7e-4589-8311-876a30957dd6",
                    "title": "sajon007",
                    "groupId": null,
                    "color": "#008080",
                    "unreadMessageCount": 5,
                    "lastMessage": {
                    "messageId": 187,
                        "text": "hmm",
                        "fileUrl": null,
                        "fileType": null,
                        "lastMessageDate": "2024-11-19T17:32:38.1918188"
                    }
                }*/

                state.chatList.items = state.chatList.items.map(_item => {
                    if (_item.id === data.id) {
                        // found = true
                        return data
                    } else return _item
                })

                // if (found) {
                //     if (state.activeChat.id === data.id && state.activeChat.agent !== data['assignedAgent']) {
                //         state.activeChat.agent = data['assignedAgent']
                //     }
                //     state.chatList.items.sort(chatListSort)
                // } else {
                //     if (action.payload.append) {
                //         state.chatList.items.unshift(data)
                //         // state.chatList.items.pop()
                //     }
                // }
            },
            prepare(data) {
                return {
                    payload: { contactId, data }
                }
            }
        },
        updateAgentList: (state, action) => {
            const data = action.payload.data
            // let found = false

            state.agentList.items = state.agentList.items.map(_item => {
                console.log("data", data, _item)
                if (_item.userId === data.userId || (_item.groupId && _item.groupId === data.groupId)) {
                    // found = true
                    return data
                } else return _item
            })
        },
        setShowChatTab: (state, action) => {
            state.showChatTab = action.payload
        },
        updateMessage: {
            reducer(state, action) {                
                if (state.activeChat.id === action.payload.contactId) {
                    const message = state.activeChat.messages.find(_message => _message.id === action.payload.message.id)
                    if (message) {
                        message.text = action.payload.message.text
                        message.isEdited = true
                    } else {
                        state.activeChat.messages.push(action.payload.message)
                    }
                } else {
                    const chat = state.history.find(_item => _item.id === action.payload.contactId)
                    const message = chat?.messages.find(_message => _message.id === action.payload.message.id)
                    if (message) {
                        message.text = action.payload.message.text
                        message.isEdited = true
                    }
                }
            },
            prepare(contactId, message) {
                return {
                    payload: { contactId, message }
                }
            }
        },
        resetActiveChat: (state) => {
            axiosController.abort()

            /*const activeChat = state.activeChat
            const messageCount = activeChat.messages.length
            const slice = messageCount >= 25 ? -25 : -messageCount
            const messages = activeChat.messages.slice(slice)

            if (activeChat.id) {
                let found = false
                state.history = state.history.map(_chat => {
                    if (_chat.id === activeChat.id) {
                        found = true
                        return {
                            isReady: true,
                            id: activeChat.id,
                            isGroup: activeChat.isGroup,
                            pinnedMessages: activeChat.pinnedMessages,
                            paginator: activeChat.paginator,
                            messages
                        }
                    } else return _chat
                })
                if (!found) state.history.push({
                    ...state.activeChat,
                    messages
                })
            }*/

            state.activeChat = {
                isReady: false,
                id: null,
                isGroup: false,
                pinnedMessages: [],
                messages: [],
                paginator: {
                    current: 1,
                    hasNext: false
                }
            }
            axiosController = new AbortController()
        },
        setReceiver: {
            reducer(state, action) {
                state.receiver = action.payload
            },
            prepare(id, groupId, fullName, profileImage, online, color) {
                return {
                    payload: { id, groupId, fullName, profileImage, online, profileBackgroundColor: color }
                }
            }
        },
        unsetReceiver: (state) => {
            state.receiver = {}
        },
        setUnReadMessageCount: (state, action) => {
            state.unreadMessageCount = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAgents.fulfilled, (state, action) => {
                state.agentList.items = action.payload || []
            })
            .addCase(loadMoreAgentMessages.rejected, (state) => {
                state.isLoading = false
            })
            .addCase(loadMoreAgentMessages.pending, (state) => {
                state.isLoading = true
            })
            .addCase(loadMoreAgentMessages.fulfilled, (state, action) => {
                if (action.payload.id === state.activeChat.id) {
                    state.activeChat.messages.unshift(...action.payload.items)
                    state.activeChat.paginator = {
                        current: action.payload.pageNumber,
                        hasNext: action.payload.hasNextPage
                    }

                    state.activeChat.messages.sort(messagesSort)

                    state.isLoading = false
                }
            })
            .addCase(selectChatForAgent.rejected, (state) => {
                state.isLoading = false
            })
            .addCase(selectChatForAgent.pending, (state) => {
                state.isLoading = true
            })
            .addCase(selectChatForAgent.fulfilled, (state, action) => {
                console.log("selectChatForAgent.fulfilled", action.payload)

                if (action.payload.history) {
                    state.activeChat = {...action.payload.history, agent: action.payload.agent}
                } else {
                    state.activeChat.isReady = true
                    state.activeChat.agent = action.payload.agent
                    state.activeChat.id = action.payload.id
                    state.activeChat.groupId = action.payload.groupId
                    state.activeChat.isGroup = action.payload.isGroup
                    state.activeChat.messages = action.payload.items
                    state.activeChat.pinnedMessages = action.payload.pinnedMessages
                    state.activeChat.paginator = {
                        current: action.payload.pageNumber,
                        hasNext: action.payload.hasNextPage
                    }
                    state.activeChat.messages.sort(messagesSort)
                }

                state.isLoading = false
            })
    }
})

export const {
    newMessage,
    deleteMessage,
    updateMessage,
    updateChatList,
    setReceiver,
    setShowChatTab,
    updateAgentList,
    resetActiveChat,
    setUnReadMessageCount,
    markAgentMessageAsRead
} = agentChatSlice.actions

export const getUnReadMessageCount = state => state.agentChat.unreadMessageCount
export const getAgentList = state => state.agentChat.agentList
export const getActiveChat = state => state.agentChat.activeChat
export const getReceiver = state => state.agentChat.receiver
export const showChatTab = state => state.agentChat.showChatTab

export default agentChatSlice.reducer